export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore()

  if (to.path.includes('/api')) {
    return
  }

  if (authStore.session_data.user && to.path === '/') {
    return navigateTo({ path: '/feed' })
  }

  if (!authStore.session_data.user) {
    console.log('redirecting to login')

    return navigateTo({ path: '/getstarted', query: { r: to.fullPath } })
  }
  /*
   else if (!authStore.session_data.user.accepted_latest_tos) {
    return navigateTo({ path: '/terms', query: { r: to.fullPath } })
  }
  */
})
